import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

import CraftTable from '../components/CraftTable.js';

import '../App.css';

function Crafting({latest, mapping, crafts, dayData, volumes, filter}) {
    const [minVolume, setMinVolume] = useState(0);

    const handleVolumeChange = (event) => {
        setMinVolume(event.target.value);
    };

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Crafting - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant='h1'
            >
                {`Crafting`}
            </Typography>
            <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                    label="Minimum Volume"
                    type="number"
                    value={minVolume}
                    onChange={handleVolumeChange}
                    size="small"
                />
            </Box>
            <CraftTable
                latest={latest}
                mapping={mapping}
                crafts={crafts}
                filter={filter}
                minVolume={minVolume}
            />
        </Container>
    </Box>;
}

export default Crafting;
