const getCraftCost = (resultId, crafts, mapping) => {
    if (!resultId || !crafts || !mapping) {
        return null;
    }

    let itemCraft = crafts.find((craft) => craft.resultItemId.toString() === resultId.toString());

    if (!itemCraft?.input) {
        return null;
    }

    let craftCost = itemCraft.input.reduce((acc, input) => {
        if (!input?.id || !mapping[input.id]?.lowestPrice?.cost) {
            return null;
        }
        return (acc === null) ? null : acc + mapping[input.id].lowestPrice.cost;
    }, 0);

    return craftCost;
};

export default getCraftCost;