import ItemIcon from './ItemIcon';

import urlFriendlyName from '../modules/urlfriendly-name.mjs';

const ItemRow = function({name, icon, prefix = ''}) {
    return <div
        className='item-wrapper'
    >
        <ItemIcon
            name={name}
            icon={icon}
        />
        <a
            href={`/item/${urlFriendlyName(name)}`}
        >
            {prefix} {name}
        </a>
    </div>;
};

export default ItemRow;