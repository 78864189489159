import {
    useMemo,
    useState,
    useRef,
} from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';

import ListboxComponent from '../components/VirtualizedAutocomplete';
import ItemIcon from '../components/ItemIcon';

import numberFormat from '../modules/number-format.mjs';
import addRecipe from '../modules/add-recipe.mjs';

import '../App.css';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

function Admin({latest, mapping, profits, dayData, volumes, filter}) {
    const [newRecipeItems, setNewRecipeItems] = useState([]);
    const [newRecipeResult, setNewRecipeResult] = useState(null);
    const [newRecipeResultCount, setNewRecipeResultCount] = useState(1);
    const countRef = useRef(null);
    const [isResult, setIsResult] = useState(false);
    const itemRef = useRef(null);
    const [currentItem, setCurrentItem] = useState(null);

    const availableItems = useMemo(() => {
        const availableItems = [];
        for (const [key, value] of Object.entries(mapping)) {
            if (value !== null) {
                availableItems.push({
                    label: mapping[key].name,
                    icon: mapping[key].icon,
                });
            }
        }

        return availableItems;
    }, [mapping]);

    const resetForm = () => {
        countRef.current.value = 1;
        setCurrentItem(null);
        setIsResult(false);
    };

    const handleAddItem = () => {
        const inputItem = Object.values(mapping).find((item) => item.name === itemRef.current.value);

        if(isResult){
            setNewRecipeResult(inputItem.id.toString());
            setNewRecipeResultCount(Number(countRef.current.value));
            resetForm();

            return true;
        }

        const currentRecipeItems = [...newRecipeItems];

        currentRecipeItems.push({
            id: inputItem.id.toString(),
            count: Number(countRef.current.value),
        });

        setNewRecipeItems(currentRecipeItems);

        resetForm();
    };

    const switchLabel = isResult ? 'Result' : 'Input';

    /*
        {
        "resultItemId": "1683",
        "requirements": [],
        "input": [
            {
                "id": "1615",
                "count": 1
            },
            {
                "id": "2357",
                "count": 1
            }
        ]
    },
    */

    const saveRecipe = () => {
        const newRecipe = {
            resultItemId: newRecipeResult,
            resultItemCount: newRecipeResultCount,
            requirements: [],
            input: newRecipeItems,
        };

        console.log(newRecipe);
        addRecipe(newRecipe);

        setNewRecipeItems([]);
        setNewRecipeResult(null);
        setNewRecipeResultCount(1);
        resetForm();
    };

    const handleTypeChange = () => {
        setIsResult(!isResult);
    };

    return <Box
        component="form"
        noValidate
        autoComplete="off"
    >
        <title>
            Admin - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant="h1"
            >
                Admin
            </Typography>
            <FormGroup>
                <Stack
                    direction={'row'}
                    spacing={2}
                >
                    <Autocomplete
                        disableListWrap
                        PopperComponent={StyledPopper}
                        ListboxComponent={ListboxComponent}
                        options={availableItems}
                        renderInput={(params) => <TextField
                            {...params}
                            inputRef={itemRef}
                            label="Input item"
                        />}
                        onChange={(event, value) => {
                            setCurrentItem(value);
                        }}
                        renderOption={(props, option, state) => [props, option, state.index]}
                        size='small'
                        sx={{ width: 300 }}
                        value={currentItem}
                    />
                    <TextField
                        defaultValue={1}
                        inputRef={countRef}
                        label="Count"
                        name="count"
                        placeholder="Count"
                        type="number"
                        size='small'
                    />
                    <FormControlLabel
                        control={<Switch
                            checked={!isResult}
                            onChange={handleTypeChange}
                            inputProps={{
                                'aria-label': 'Item type',
                            }}
                        />}
                        label = {switchLabel}
                    />
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleAddItem();
                        }}
                    >
                        {'Add'}
                    </Button>
                </Stack>
            </FormGroup>

            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                    mt: 3,
                    mb: 3,
                    borderLeft: newRecipeResult ? 4 : 1,
                    borderColor: newRecipeResult ? 'primary.main' : 'divider',
                    pl: 2
                }}
            >
                <Typography variant="subtitle1" color="text.secondary">
                    Output:
                </Typography>
                {newRecipeResult ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ItemIcon
                            name={mapping[newRecipeResult].name}
                            icon={mapping[newRecipeResult].icon}
                            style={{ width: 24, height: 24 }}
                        />
                        <Typography>
                            {mapping[newRecipeResult].name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            (ID: {newRecipeResult})
                        </Typography>
                    </Stack>
                ) : (
                    <Typography color="text.secondary">
                        None selected
                    </Typography>
                )}
            </Stack>

            <TableContainer
                sx={{
                    mb: 3,
                    "& .MuiTableCell-root": {
                        py: 1.5
                    }
                }}
            >
                <Table
                    sx={{ minWidth: 650 }}
                    aria-label="recipe inputs table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>
                                Input Items
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                Count
                            </TableCell>
                            <TableCell
                                align="right"
                            >
                                Value
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={{ pr: 3 }}
                            >
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {newRecipeItems.length === 0 ? (
                        <TableRow>
                            <TableCell
                                colSpan={4}
                                align="center"
                                sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                            >
                                No input items added yet
                            </TableCell>
                        </TableRow>
                    ) : (
                        newRecipeItems.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ pl: 3 }}
                                >
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <ItemIcon
                                            name={mapping[row.id].name}
                                            icon={mapping[row.id].icon}
                                            style={{ width: 24, height: 24 }}
                                        />
                                        <Typography>
                                            {mapping[row.id].name}
                                        </Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    {row.count}
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    {numberFormat(latest[row.id].high * row.count)}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ pr: 3 }}
                                >
                                    <Tooltip title="Remove item">
                                        <IconButton
                                            size="small"
                                            color="default"
                                            onClick={() => {
                                                const updatedItems = newRecipeItems.filter(item => item.id !== row.id);
                                                setNewRecipeItems(updatedItems);
                                            }}
                                            sx={{
                                                '&:hover': {
                                                    color: 'error.main',
                                                }
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                onClick={() => {
                    saveRecipe();
                }}
                disabled={newRecipeItems.length === 0 || !newRecipeResult}
                sx={{ mb: 3 }}
            >
                Save Recipe
            </Button>
        </Container>
    </Box>;
}

export default Admin;
