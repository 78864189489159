import {
    useState,
    useMemo,
    // useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import ItemRow from '../components/ItemRow.js';
import StickyTable from '../components/StickyTable.js';

import numberFormat from '../modules/number-format.mjs';
import runescapeNumberFormat from '../modules/runescape-number-format.mjs';
import calculateStoreProfit from '../modules/calculate-store-profit.mjs';
import useStoresData from '../hooks/useStoresData';

// import stores from '../data/stores.json';
import itemProperties from '../data/item-properties.json';

import '../App.css';

function StoreProfits({mapping, latest, volumes, filter}) {
    const [sellToStore, setSellToStore] = useState(false);
    const [onlyStackable, setOnlyStackable] = useState(true);
    const { storesData, isLoading, error } = useStoresData();

    const rows = useMemo(() => {
        if (!storesData) {
            return [];
        }

        let storeItemRows = [];
        const mappingLookup = {};

        for(const itemId in mapping){
            mappingLookup[mapping[itemId].name] = mapping[itemId];
        }

        for (const [id, storeItem] of storesData.entries()) {
            if(isNaN(storeItem.sellPrice)){
                continue;
            }

            if (!mappingLookup[storeItem.name]) {
                // console.log(`No mapping for ${storeItem.name}`);
                continue;
            }

            if(storeItem.quantity <= 1){
                continue;
            }

            if(storeItem.sellPrice <= 0){
                continue;
            }

            if(onlyStackable && !itemProperties[mappingLookup[storeItem.name].id]?.stackable){
                continue;
            }

            storeItem.volume = volumes[mappingLookup[storeItem.name].id] || 0;

            if(filter && !storeItem.name.toLowerCase().includes(filter.toLowerCase())){
                continue;
            }

            // Remove all items with a low volume
            if(volumes[mappingLookup[storeItem.name].id] < 400){
                // console.log(`Low volume: ${storeItem.name} (${volumes[mappingLookup[storeItem.name].id]})`);
                continue;
            }

            storeItem.gePrice = latest[mappingLookup[storeItem.name].id].high;
            storeItem.profitRatio = storeItem.gePrice / storeItem.sellPrice;

            // storeItem.storeProfit = storeItem.quantity * (storeItem.gePrice - storeItem.sellPrice);
            storeItem.storeCost = calculateStoreProfit(storeItem.sellPrice, storeItem.storeChangeRate, storeItem.quantity)

            if(sellToStore){
                storeItem.profitRatio = storeItem.buyPrice / storeItem.gePrice;
                let profitableQty = 0;
                let totalProfit = 0;
                let currentPrice = storeItem.buyPrice;
                let priceDetails = [];

                // Calculate until price drops below GE price
                while (currentPrice > storeItem.gePrice && profitableQty < storeItem.quantity) {
                    totalProfit += currentPrice - storeItem.gePrice;
                    priceDetails.push({
                        itemNumber: profitableQty + 1,
                        price: Math.floor(currentPrice),
                        profit: Math.floor(currentPrice - storeItem.gePrice)
                    });
                    // Calculate price drop based on current price
                    currentPrice = currentPrice * (1 - storeItem.storeChangeRate/100);
                    profitableQty++;
                }

                storeItem.profitableQty = profitableQty;
                storeItem.storeProfit = totalProfit;
                storeItem.perWorldProfit = totalProfit;
                storeItem.priceDetails = priceDetails;
            } else {
                storeItem.profitRatio = storeItem.gePrice / storeItem.sellPrice;
                storeItem.storeProfit = storeItem.quantity * storeItem.gePrice - storeItem.storeCost;
                storeItem.perWorldProfit = storeItem.storeProfit;
            }

            if(!sellToStore && !itemProperties[mappingLookup[storeItem.name].id]?.stackable){
                storeItem.storeProfit = Math.min(27, storeItem.quantity) * storeItem.gePrice - storeItem.storeCost;
                storeItem.perWorldProfit = storeItem.storeProfit;
            }

            storeItemRows.push({
                ...storeItem,
                id: id,
                itemId: mappingLookup[storeItem.name].id,
                icon: mappingLookup[storeItem.name].icon,
            });
        }

        return storeItemRows;
    }, [mapping, latest, volumes, sellToStore, onlyStackable, filter, storesData]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            renderCell: ({row}) => {
                return (
                    <div>
                        <ItemRow
                            name={row.name}
                            icon={row.icon}
                            id={row.itemId}
                        />
                        <a
                            className='subtext'
                            href = {row.store}
                        >
                            {decodeURIComponent(row.store
                                    .replace('https://oldschool.runescape.wiki/w/', '')
                                    .replace(/_/g, ' ')
                                    .replace(/\.$/, '')
                            )}
                        </a>
                    </div>
                );
            },
            minWidth: 200,
        },
        {
            field: 'quantity',
            headerName: 'Stock',
            renderCell: ({ value }) => numberFormat(value),
            // width: 150,
        },
    ];

    if(sellToStore){
        columns.push(
            {
                field: 'buyPrice',
                headerName: 'Store buy price',
                renderCell: ({ value }) => numberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'gePrice',
                headerName: 'GE price',
                renderCell: ({ value }) => numberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'volume',
                headerName: 'GE volume',
                renderCell: ({ value }) => runescapeNumberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'profitRatio',
                headerName: 'Profit ratio',
                renderCell: ({ value }) => numberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'profitableQty',
                headerName: 'Profitable qty/world',
                renderCell: ({ value }) => numberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'perWorldProfit',
                headerName: 'Per world',
                renderCell: ({row}) => {
                    return <div>
                        {numberFormat(Math.round(row.perWorldProfit))}
                        <span
                            className='subtext'
                        >
                            {`Hourly: ${runescapeNumberFormat(Math.round(row.perWorldProfit * 240))}`}
                        </span>
                    </div>;
                },
                // width: 150,
            }
        );
    } else {
        columns.push(
            {
                field: 'sellPrice',
                headerName: 'Store price',
                renderCell: ({ value }) => numberFormat(value),
                // width: 150,
            },
            {
                field: 'gePrice',
                headerName: 'GE price',
                renderCell: ({ value }) => numberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'volume',
                headerName: 'GE volume',
                renderCell: ({ value }) => runescapeNumberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'profitRatio',
                headerName: 'Profit ratio',
                renderCell: ({ value }) => numberFormat(value) || '',
                // width: 150,
            },
            {
                field: 'storeProfit',
                headerName: 'Per world',
                renderCell: ({row}) => {
                    return <div>
                        {numberFormat(row.storeProfit)}
                        <span
                            className='subtext'
                        >
                            {`Hourly: ${runescapeNumberFormat(row.storeProfit * 240)}`}
                        </span>
                    </div>;
                },
                // width: 150,
            }
        );
    }

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Stores - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant="h1"
            >
                Stores
            </Typography>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            ) : (
                <>
                    <FormGroup>
                        <Stack
                            direction="row"
                        >
                            <FormControlLabel control={
                                <Checkbox
                                    checked={sellToStore}
                                    label="Sell to store"
                                    onChange={(event) => {
                                        setSellToStore(event.target.checked);
                                    }}
                                />
                            } label="Sell to store" />
                            <FormControlLabel control={
                                <Checkbox
                                    checked={onlyStackable}
                                    label="Show only stackable"
                                    onChange={(event) => {
                                        setOnlyStackable(event.target.checked);
                                    }}
                                />
                            } label="Show only stackable" />
                        </Stack>
                    </FormGroup>
                    <StickyTable
                        density="standard"
                        rows={rows}
                        columns={columns}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                            sorting: {
                                sortModel: [{
                                    field: sellToStore ? 'perWorldProfit' : 'storeProfit',
                                    sort: 'desc',
                                }],
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                    />
                </>
            )}
        </Container>
    </Box>;
}

export default StoreProfits;
