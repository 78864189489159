import isDataUrl from '../modules/is-data-url.mjs';

const ItemIcon = ({name, icon, style}) => {
    let src = '';

    if(isDataUrl(icon)) {
        src = icon;
    } else if(icon?.startsWith('/images/')) {
        // Handle local image paths
        src = `${process.env.PUBLIC_URL}${icon}`;
    } else {
        // Handle wiki URLs
        icon = icon?.replace(/ /g, '_');
        src = `https://oldschool.runescape.wiki/images/${icon}?cache`;
    }

    return (
        <div
            className="item-image-wrapper"
            style={style}
        >
            <img
                alt = {`${name} icon`}
                src={src}
            />
        </div>
    );
};

export default ItemIcon;
