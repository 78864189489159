import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import GrandExchangeTable from '../components/GrandExchangeTable.js';
import LoadingOverlay from '../components/LoadingOverlay.js';

import '../App.css';

function Items({latest, mapping, dayData, volumes, filter, playerStats}) {
    // Show loading state if any of the required data is not yet available or empty
    if (!latest || !mapping || !dayData || !volumes ||
        Object.keys(latest).length === 0 ||
        Object.keys(mapping).length === 0 ||
        Object.keys(dayData).length === 0 ||
        Object.keys(volumes).length === 0) {
        return <LoadingOverlay />;
    }

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Grand Exchange - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant="h1"
            >
                {'Grand Exchange'}
            </Typography>
            <GrandExchangeTable
                latest={latest}
                mapping={mapping}
                filter={filter}
                dayData={dayData}
                volumes={volumes}
                playerStats={playerStats}
                shuffle
            />
        </Container>
    </Box>;
}

export default Items;
