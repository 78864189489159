import React, { useState, useMemo } from 'react';
import { Box, Typography, Container, CircularProgress, Alert, Checkbox, FormControlLabel } from '@mui/material';
import StickyTable from '../components/StickyTable';
import ItemRow from '../components/ItemRow.js';
import InfoAlert from '../components/InfoAlert.js';
import numberFormat from '../modules/number-format.mjs';

function Herblore({ latest, mapping, dayData, crafts }) {
    const [amuletOfChemistry, setAmuletOfChemistry] = useState(false);
    const [amuletOfAlchemy, setAmuletOfAlchemy] = useState(false);
    const [prescriptionGoggles, setPrescriptionGoggles] = useState(false);

    const rows = useMemo(() => {
        let potionRows = [];

        // Iterate over the crafts object
        for (const craftId in crafts) {
            const craft = crafts[craftId];
            const potionItem = mapping[craft.resultItemId];
            if (!potionItem) {
                continue;
            }

            // Only include potions (items that end with (3) and are herblore-related)
            if (!potionItem.name.endsWith('(3)') ||
                !potionItem.name.toLowerCase().includes('potion') ||
                potionItem.name.toLowerCase().includes('divine') ||
                potionItem.name.toLowerCase().includes('bastion') ||
                potionItem.name.toLowerCase().includes('battlemage')) {
                continue;
            }

            let ingredientCost = 0;
            const ingredientDetails = craft.input.map(input => {
                const ingredientItem = mapping[input.id];
                if (!ingredientItem) {
                    console.log(`Could not find ingredient in mapping: ${input.id}`);
                }
                const cost = ingredientItem ? latest[input.id]?.high || 0 : 0;

                // Apply prescription goggles effect to secondary ingredients (not vials)
                const isSecondary = !ingredientItem?.name.toLowerCase().includes('vial');
                const savedChance = prescriptionGoggles && isSecondary ? 0.1 : 0;
                const actualCount = input.count * (1 - savedChance);
                ingredientCost += cost * actualCount;
                return { name: ingredientItem?.name || input.id, cost: cost * actualCount };
            });

            const potionValue = latest[craft.resultItemId]?.high || 0;
            // Apply amulet of chemistry effect (5% chance for extra dose)
            const chemistryExtraDoseChance = amuletOfChemistry ? 0.05 : 0;
            // Apply alchemist's amulet effect (15% chance for extra dose)
            const alchemyExtraDoseChance = amuletOfAlchemy ? 0.15 : 0;
            // Combine both extra dose chances
            const totalExtraDoseChance = chemistryExtraDoseChance + alchemyExtraDoseChance;
            const expectedPotionValue = potionValue * (1 + totalExtraDoseChance);
            const profit = expectedPotionValue - ingredientCost;

            potionRows.push({
                id: craft.resultItemId,
                name: potionItem.name,
                level: craft.level || 0,
                ingredients: ingredientDetails.map(ing => ing.name),
                ingredientDetails,
                ingredientCost,
                potionValue: expectedPotionValue,
                profit,
                icon: potionItem.icon
            });
        }

        return potionRows;
    }, [crafts, mapping, latest, amuletOfChemistry, amuletOfAlchemy, prescriptionGoggles]);

    const filteredAndSortedPotions = rows
        .sort((a, b) => b.profit - a.profit);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        {
            field: 'name',
            headerName: 'Potion',
            flex: 1,
            renderCell: ({row}) => {
                return <ItemRow
                    name={row.name}
                    icon={row?.icon}
                    id={row?.id}
                />;
            },
            minWidth: 200
        },
        {
            field: 'level',
            headerName: 'Level',
            width: 100
        },
        {
            field: 'ingredients',
            headerName: 'Ingredients',
            width: 300,
            renderCell: ({ value }) => {
                return (
                    <div className="field-list-wrapper">
                        {value.map((ingredient, index) => {
                            const ingredientItem = Object.values(mapping).find(item => item.name === ingredient);
                            const cost = ingredientItem ? latest[ingredientItem.id]?.high || 0 : 0;
                            return <div key={index} style={{ marginBottom: '4px' }}>
                                {ingredient}: {numberFormat(cost)} gp
                            </div>;
                        })}
                    </div>
                );
            }
        },
        {
            field: 'ingredientCost',
            headerName: 'Total Cost',
            width: 150,
            renderCell: ({ value }) => numberFormat(value)
        },
        {
            field: 'potionValue',
            headerName: 'Value',
            width: 150,
            renderCell: ({ value }) => numberFormat(value)
        },
        {
            field: 'profit',
            headerName: 'Profit',
            width: 150,
            renderCell: ({ value }) => (
                <span style={{ color: value > 0 ? '#2e7d32' : '#d32f2f', fontWeight: 'bold' }}>
                    {numberFormat(value)}
                </span>
            )
        }
    ];

    const calculateRowHeight = (params) => {
        const uniqueItems = [...new Set(params.model.ingredients)];
        return uniqueItems.length * 25 + (16 * params.densityFactor);
    };

    if (!latest || !mapping) {
        return (
            <Container maxWidth="lg">
                <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (Object.keys(mapping).length === 0) {
        return (
            <Container maxWidth="lg">
                <Box sx={{ my: 4 }}>
                    <Alert severity="error">
                        No item mapping data available. Please try refreshing the page.
                    </Alert>
                </Box>
            </Container>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <title>
                Herblore - OldSchool Zone
            </title>
            <Container>
                <Typography variant="h1">
                    Herblore
                </Typography>
                <InfoAlert>
                    Calculate profits from making different potions. The calculator shows ingredient costs, potion values,
                    and potential profits based on current Grand Exchange prices.
                </InfoAlert>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={amuletOfChemistry}
                                    onChange={(e) => {
                                        setAmuletOfChemistry(e.target.checked);
                                        if (e.target.checked) {
                                            setAmuletOfAlchemy(false);
                                        }
                                    }}
                                    size="small"
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body2">Amulet of Chemistry</Typography>
                                    <Typography variant="caption" color="text.secondary">5% chance for extra dose</Typography>
                                </Box>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={amuletOfAlchemy}
                                    onChange={(e) => {
                                        setAmuletOfAlchemy(e.target.checked);
                                        if (e.target.checked) {
                                            setAmuletOfChemistry(false);
                                        }
                                    }}
                                    size="small"
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body2">Alchemist's amulet</Typography>
                                    <Typography variant="caption" color="text.secondary">15% chance for extra dose</Typography>
                                </Box>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={prescriptionGoggles}
                                    onChange={(e) => setPrescriptionGoggles(e.target.checked)}
                                    size="small"
                                />
                            }
                            label={
                                <Box>
                                    <Typography variant="body2">Prescription Goggles</Typography>
                                    <Typography variant="caption" color="text.secondary">10% chance to save secondary ingredients</Typography>
                                </Box>
                            }
                        />
                    </Box>
                </Box>
                <StickyTable
                    density="standard"
                    rows={filteredAndSortedPotions}
                    columns={columns}
                    getRowHeight={calculateRowHeight}
                    columnVisibilityModel={{
                        id: false,
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                        sorting: {
                            sortModel: [{
                                field: 'profit',
                                sort: 'desc',
                            }],
                        },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    hideFooter
                />
            </Container>
        </Box>
    );
}

export default Herblore;