import { useState, useEffect } from 'react';

export function useStoresData() {
    const [storesData, setStoresData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStoresData = async () => {
            try {
                const response = await fetch('/data/stores.json');
                if (!response.ok) {
                    throw new Error('Failed to load stores data');
                }
                const data = await response.json();
                setStoresData(data);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchStoresData();
    }, []);

    return { storesData, isLoading, error };
}

export default useStoresData;