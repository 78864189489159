import Alert from '@mui/material/Alert';

function InfoAlert({ children }) {
    return (
        <Alert
            severity="info"
            sx={{
                mt: { xs: 1, sm: 2 },
                mb: { xs: 1, sm: 2 },
                backgroundColor: 'transparent',
                border: { xs: 'none', sm: '1px solid rgba(255, 255, 255, 0.12)' },
                color: 'text.primary',
                padding: { xs: 0, sm: '6px 16px' },
                '& .MuiAlert-icon': {
                    color: 'text.secondary',
                    display: { xs: 'none', sm: 'block' }
                },
                '& .MuiAlert-message': {
                    padding: { xs: 0, sm: '8px 0' }
                }
            }}
        >
            {children}
        </Alert>
    );
}

export default InfoAlert;