import {
    useState,
    useMemo,
    useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import StickyTable from '../components/StickyTable';
import ItemRow from '../components/ItemRow';

import numberFormat from '../modules/number-format.mjs';
import runescapeNumberFormat from '../modules/runescape-number-format.mjs';
import loadJSON from '../modules/load-json.mjs';

import '../App.css';

const itemType = [
    '2h',
    'ammo',
    'body',
    'cape',
    'feet',
    'hands',
    'head',
    'legs',
    'neck',
    'ring',
    'shield',
    'weapon',
];

function Gear({mapping, latest, filter, volumes}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentType = location.pathname.split('/').pop() || '2h';
    const [selectedType, setSelectedType] = useState(currentType);
    const [items, setItems] = useState([]);
    const [onlyBuyable, setOnlyBuyable] = useState(false);
    const [sortField, setSortField] = useState(searchParams.get('sort') || null);
    const [sortDirection, setSortDirection] = useState(searchParams.get('order') || 'asc');
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        // Redirect to default gear type if just visiting /gear
        if (location.pathname === '/gear') {
            navigate('/gear/2h');
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        const type = location.pathname.split('/').pop() || '2h';
        if (type !== selectedType) {
            setSelectedType(type);
        }
    }, [location.pathname, selectedType]);

    useEffect(() => {
        // Format the slot name for better readability
        const formatSlotName = (slot) => {
            const slotMap = {
                '2h': 'Two-Handed',
                'ammo': 'Ammunition',
                'body': 'Body',
                'cape': 'Cape',
                'feet': 'Footwear',
                'hands': 'Handwear',
                'head': 'Headwear',
                'legs': 'Legwear',
                'neck': 'Neckwear',
                'ring': 'Ring',
                'shield': 'Shield',
                'weapon': 'Weapon'
            };
            return slotMap[slot] || slot.charAt(0).toUpperCase() + slot.slice(1);
        };

        const slotTitle = formatSlotName(currentType);
        document.title = `${slotTitle} Equipment - OSRS Profit Calculator`;
    }, [currentType]);

    const handleSort = (field) => {
        if (sortField === field) {
            if (sortDirection === 'desc') {
                setSortDirection('asc');
                setSearchParams({ sort: field, order: 'asc' });
            } else if (sortDirection === 'asc') {
                setSortField(null);
                setSortDirection('desc');
                setSearchParams({});
            }
        } else {
            setSortField(field);
            setSortDirection('desc');
            setSearchParams({ sort: field, order: 'desc' });
        }
    };

    // Add effect to sync URL params with sort state on mount
    useEffect(() => {
        const sortParam = searchParams.get('sort');
        const orderParam = searchParams.get('order');

        if (sortParam && orderParam) {
            setSortField(sortParam);
            setSortDirection(orderParam);
        }
    }, [searchParams]);

    const renderSortArrow = (field) => {
        if (sortField !== field) return null;
        return (
            <div
                style={{
                    paddingLeft: '6px',
                    fontSize: '24px',
                    lineHeight: '24px',
                    fontWeight: 'bold'
                }}
            >
                {sortDirection === 'asc' ? '↑' : '↓'}
            </div>
        );
    };

    const rows = useMemo(() => {
        let itemRows = [];

        for(const item of items) {
            const newItem = {
                id: item.id,
                name: mapping[item.id]?.name || item.name,
                icon: item.iconPath,
                crush: item.equipment.attack_crush,
                slash: item.equipment.attack_slash,
                stab: item.equipment.attack_stab,
                magic: item.equipment.attack_magic,
                ranged: item.equipment.attack_ranged,
                meleeStrength: item.equipment.melee_strength,
                rangedStrength: item.equipment.ranged_strength,
                magicDamage: item.equipment.magic_damage,
                prayer: item.equipment.prayer,
                weight: item.weight,
                requirements: item.equipment.requirements,
                crushDefence: item.equipment.defence_crush,
                slashDefence: item.equipment.defence_slash,
                stabDefence: item.equipment.defence_stab,
                magicDefence: item.equipment.defence_magic,
                rangedDefence: item.equipment.defence_ranged,
                high: latest[item.id]?.high || 0,
            };

            if(filter && !newItem.name.toLowerCase().includes(filter.toLowerCase())){
                continue;
            }

            if(onlyBuyable && newItem.high <= 0) {
                continue;
            }

            itemRows.push(newItem);
        };

        return itemRows;
    }, [items, filter, mapping, latest, onlyBuyable]);

    const sortedRows = useMemo(() => {
        // Return original rows if no sort field or empty rows
        if (!sortField || !rows.length) {
            return rows;
        }

        // Create copy of rows array to sort
        const rowsCopy = [...rows];

        // Custom comparison function
        const compareValues = (a, b) => {
            const valueA = a[sortField];
            const valueB = b[sortField];

            // Handle equal values
            if (valueA === valueB) {
                return 0;
            }

            // Handle null/undefined values by moving them to end
            if (valueA == null) {
                return 1;
            }
            if (valueB == null) {
                return -1;
            }

            // Sort ascending or descending
            const comparison = valueA - valueB;
            return sortDirection === 'asc' ? comparison : -comparison;
        };

        return rowsCopy.sort(compareValues);
    }, [rows, sortField, sortDirection]);

    useEffect(() => {
        async function fetchData(params) {
            const itemData = await loadJSON(`${process.env.PUBLIC_URL}/items-${selectedType}.json`);
            setItems(Object.values(itemData));
        }

        fetchData();
    }, [selectedType]);

    const createStatColumn = (field, imagePath, altText) => ({
        align: 'center',
        headerAlign: 'center',
        field,
        sortable: false,
        headerName: '',
        renderHeader: () => (
            <div
                style={{ width: '100%', textAlign: 'center', cursor: 'pointer', position: 'relative', display: 'flex' }}
                onClick={() => handleSort(field)}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/images/${imagePath}`}
                    alt={altText}
                    style={{ height: '24px' }}
                />
                {renderSortArrow(field)}
            </div>
        ),
        renderCell: ({ value }) => numberFormat(value) || '',
        width: 60,
    });

    const statColumns = {
        attack: [
            createStatColumn('stab', 'attack-styles/stab.webp', 'Stab'),
            createStatColumn('slash', 'attack-styles/slash.webp', 'Slash'),
            createStatColumn('crush', 'attack-styles/crush.webp', 'Crush'),
            createStatColumn('magic', 'stats/magic.webp', 'Magic'),
            createStatColumn('ranged', 'stats/ranged.webp', 'Ranged'),
        ],
        defence: [
            createStatColumn('stabDefence', 'defence-styles/stab.webp', 'Stab Defence'),
            createStatColumn('slashDefence', 'defence-styles/slash.webp', 'Slash Defence'),
            createStatColumn('crushDefence', 'defence-styles/crush.webp', 'Crush Defence'),
            createStatColumn('magicDefence', 'defence-styles/magic.webp', 'Magic Defence'),
            createStatColumn('rangedDefence', 'defence-styles/ranged.webp', 'Ranged Defence'),
        ],
        other: [
            createStatColumn('meleeStrength', 'stats/strength.webp', 'Melee Strength'),
            createStatColumn('rangedStrength', 'stats/ranged_strength.webp', 'Ranged Strength'),
            createStatColumn('magicDamage', 'stats/magic_damage.webp', 'Magic Damage'),
            createStatColumn('prayer', 'stats/prayer.webp', 'Prayer'),
            createStatColumn('weight', 'stats/weight.webp', 'Weight'),
        ],
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            sortable: false,
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            sortable: false,
            renderCell: ({row}) => {
                return (
                    <div
                        className={`item-row-wrapper ${row.id}`}
                    >
                        <ItemRow
                            name={row.name}
                            icon={row.icon}
                            id={row.itemId}
                        />
                        { row.high > 0 && (
                            <div
                                className='subtext'
                            >
                                {runescapeNumberFormat(row.high)}
                            </div>
                        )}
                    </div>
                );
            },
            minWidth: 150,
        },
        ...statColumns.attack,
        ...statColumns.defence,
        ...statColumns.other,
    ];

    const columnGroupingModel = [
        {
            groupId: 'attack',
            headerName: 'Attack',
            children: [
                { field: 'crush' },
                { field: 'slash' },
                { field: 'stab' },
                { field: 'magic' },
                { field: 'ranged' },
            ],
        },
        {
            groupId: 'defence',
            headerName: 'Defence',
            children: [
                { field: 'crushDefence' },
                { field: 'slashDefence' },
                { field: 'stabDefence' },
                { field: 'magicDefence' },
                { field: 'rangedDefence' },
            ],
        },
        {
            groupId: 'other',
            headerName: 'Other',
            children: [
                { field: 'meleeStrength' },
                { field: 'rangedStrength' },
                { field: 'magicDamage' },
                { field: 'prayer' },
                { field: 'weight' },
            ],
        },
    ];

    const handleTypeChange = (type) => {
        setSelectedType(type);
        navigate(`/gear/${type}`);
    };

    // const calculateRowHeight = (params) => {
    //     const uniqueItems = [...new Set(params.model.input)];

    //     return uniqueItems.length * 25 + (16 * params.densityFactor);
    // };

    return <Box
        component="form"
        noValidate
        autoComplete="off"
    >
        <title>
            Gear - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant='h1'
            >
                Gear
            </Typography>
            <FormGroup
                sx={{
                    marginBottom: '1rem',
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >
                    {isMobile ? (
                        <Select
                            value={selectedType}
                            onChange={(event) => handleTypeChange(event.target.value)}
                            sx={{ minWidth: 120 }}
                        >
                            {itemType.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <ButtonGroup
                            aria-label="Small button group"
                        >
                            {itemType.map((type) => {
                                return <Button
                                    key={type}
                                    onClick={() => handleTypeChange(type)}
                                    variant={selectedType === type ? 'contained' : 'outlined'}
                                >
                                    {type}
                                </Button>;
                            })}
                        </ButtonGroup>
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={onlyBuyable}
                                label="Only buyable"
                                onChange={(event) => {
                                    setOnlyBuyable(event.target.checked);
                                }}
                            />
                        }
                        label="Show only buyable"
                    />
                </Stack>
            </FormGroup>
            <StickyTable
                density="standard"
                rows={sortedRows}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
            />
        </Container>
    </Box>;
}

export default Gear;

