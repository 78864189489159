import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
}));

const LoadingOverlay = () => {
    return (
        <StyledGridOverlay>
            <CircularProgress />
            <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">
                    Loading Grand Exchange data...
                </Typography>
            </Box>
        </StyledGridOverlay>
    );
}

export default LoadingOverlay;