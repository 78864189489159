import {
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ItemRow from '../components/ItemRow';
import numberFormat from '../modules/number-format.mjs';
import useStateWithLocalStorage from '../hooks/useStateWithLocalStorage';

import '../App.css';

const HERB_PATCHES = [
    { id: 'falador', name: 'Falador', default: true },
    { id: 'ardougne', name: 'Ardougne', default: true },
    { id: 'catherby', name: 'Catherby', default: true },
    { id: 'hosidius', name: 'Hosidius', default: true },
    { id: 'morytania', name: 'Morytania', default: true },
    { id: 'trollheim', name: 'Trollheim', default: true },
    { id: 'weiss', name: 'Weiss', default: true },
    { id: 'farming_guild', name: 'Farming Guild', default: true },
    { id: 'harmony', name: 'Harmony Island', default: false },
    { id: 'varlamore', name: 'Varlamore', default: true },
];

const ALLOTMENT_PATCHES = [
    { id: 'falador_north', name: 'Falador North', default: true },
    { id: 'falador_south', name: 'Falador South', default: true },
    { id: 'catherby_north', name: 'Catherby North', default: true },
    { id: 'catherby_south', name: 'Catherby South', default: true },
    { id: 'ardougne_north', name: 'Ardougne North', default: true },
    { id: 'ardougne_south', name: 'Ardougne South', default: true },
    { id: 'hosidius_north', name: 'Hosidius North', default: true },
    { id: 'hosidius_south', name: 'Hosidius South', default: true },
    { id: 'morytania_north', name: 'Morytania North', default: true },
    { id: 'morytania_south', name: 'Morytania South', default: true },
    { id: 'prifddinas_north', name: 'Prifddinas North', default: false },
    { id: 'prifddinas_south', name: 'Prifddinas South', default: false },
    { id: 'farming_guild_north', name: 'Farming Guild North', default: true },
    { id: 'farming_guild_south', name: 'Farming Guild South', default: true },
    { id: 'varlamore_north', name: 'Varlamore North', default: true },
    { id: 'varlamore_south', name: 'Varlamore South', default: true },
];

const TREE_PATCHES = [
    { id: 'falador', name: 'Falador', default: true },
    { id: 'taverley', name: 'Taverley', default: true },
    { id: 'gnome_stronghold', name: 'Gnome Stronghold', default: true },
    { id: 'lumbridge', name: 'Lumbridge', default: true },
    { id: 'varrock', name: 'Varrock', default: true },
    { id: 'farming_guild', name: 'Farming Guild', default: true },
    { id: 'prifddinas', name: 'Prifddinas', default: false },
];

const BERRY_PATCHES = [
    { id: 'rimmington', name: 'Rimmington', default: true },
    { id: 'ardougne', name: 'Ardougne', default: true },
    { id: 'champion_guild', name: 'Champion\'s Guild', default: true },
    { id: 'farming_guild', name: 'Farming Guild', default: true },
    { id: 'prifddinas', name: 'Prifddinas', default: false },
];

const AVG_HERBS_PER_PATCH = 8.5;

const AVG_ALLOTMENTS_PER_PATCH = 10.5; // Average yield per patch with Ultracompost

const herbData = [
    { id: 'guam', name: 'Guam', levelRequired: 9, seedId: 5291, herbId: 199 },
    { id: 'marrentill', name: 'Marrentill', levelRequired: 14, seedId: 5292, herbId: 201 },
    { id: 'tarromin', name: 'Tarromin', levelRequired: 19, seedId: 5293, herbId: 203 },
    { id: 'harralander', name: 'Harralander', levelRequired: 26, seedId: 5294, herbId: 205 },
    { id: 'ranarr', name: 'Ranarr', levelRequired: 32, seedId: 5295, herbId: 207 },
    { id: 'toadflax', name: 'Toadflax', levelRequired: 38, seedId: 5296, herbId: 3049 },
    { id: 'irit', name: 'Irit', levelRequired: 44, seedId: 5297, herbId: 209 },
    { id: 'avantoe', name: 'Avantoe', levelRequired: 50, seedId: 5298, herbId: 211 },
    { id: 'kwuarm', name: 'Kwuarm', levelRequired: 56, seedId: 5299, herbId: 213 },
    { id: 'snapdragon', name: 'Snapdragon', levelRequired: 62, seedId: 5300, herbId: 3051 },
    { id: 'cadantine', name: 'Cadantine', levelRequired: 67, seedId: 5301, herbId: 215 },
    { id: 'lantadyme', name: 'Lantadyme', levelRequired: 73, seedId: 5302, herbId: 2485 },
    { id: 'huasca', name: 'Huasca', levelRequired: 76, seedId: 30088, herbId: 30094 },
    { id: 'dwarf_weed', name: 'Dwarf Weed', levelRequired: 79, seedId: 5303, herbId: 217 },
    { id: 'torstol', name: 'Torstol', levelRequired: 85, seedId: 5304, herbId: 219 },
];

const allotmentData = [
    { id: 'potato', name: 'Potato', levelRequired: 1, seedId: 5318, produceId: 1942 },
    { id: 'onion', name: 'Onion', levelRequired: 5, seedId: 5319, produceId: 1957 },
    { id: 'cabbage', name: 'Cabbage', levelRequired: 7, seedId: 5324, produceId: 1965 },
    { id: 'tomato', name: 'Tomato', levelRequired: 12, seedId: 5322, produceId: 1982 },
    { id: 'sweetcorn', name: 'Sweetcorn', levelRequired: 20, seedId: 5320, produceId: 5986 },
    { id: 'strawberry', name: 'Strawberry', levelRequired: 31, seedId: 5323, produceId: 5504 },
    { id: 'watermelon', name: 'Watermelon', levelRequired: 47, seedId: 5321, produceId: 5982 },
    { id: 'snape_grass', name: 'Snape grass', levelRequired: 61, seedId: 22879, produceId: 231 },
];

const treeData = [
    { id: 'oak', name: 'Oak', levelRequired: 15, seedId: 5370, produceId: 1521, depletionChance: 1/8 },
    { id: 'willow', name: 'Willow', levelRequired: 30, seedId: 5371, produceId: 1519, depletionChance: 1/8 },
    { id: 'maple', name: 'Maple', levelRequired: 45, seedId: 5372, produceId: 1517, depletionChance: 1/8 },
    { id: 'yew', name: 'Yew', levelRequired: 60, seedId: 5373, produceId: 1515, depletionChance: 1/8 },
    { id: 'magic', name: 'Magic', levelRequired: 75, seedId: 5374, produceId: 1513, depletionChance: 1/8 },
    { id: 'redwood', name: 'Redwood', levelRequired: 90, seedId: 19669, produceId: 19669, depletionChance: 1/11 },
];

const berryData = [
    { id: 'redberry', name: 'Redberry', levelRequired: 10, seedId: 5101, produceId: 1951 },
    { id: 'cadavaberry', name: 'Cadavaberry', levelRequired: 22, seedId: 5102, produceId: 753 },
    { id: 'dwellberry', name: 'Dwellberry', levelRequired: 36, seedId: 5103, produceId: 2126 },
    { id: 'jangerberry', name: 'Jangerberry', levelRequired: 48, seedId: 5104, produceId: 247 },
    { id: 'whiteberry', name: 'Whiteberry', levelRequired: 59, seedId: 5105, produceId: 239 },
    { id: 'poison_ivy', name: 'Poison ivy', levelRequired: 70, seedId: 5106, produceId: 6018 },
];

function Farming({mapping, latest, playerStats}) {
    const [activeTab, setActiveTab] = useStateWithLocalStorage('farming_active_tab', 0);
    const [hideUnqualified, setHideUnqualified] = useStateWithLocalStorage('farming_hide_unqualified', true);
    const [activeHerbPatches, setActiveHerbPatches] = useStateWithLocalStorage('farming_active_herb_patches',
        HERB_PATCHES.reduce((acc, patch) => ({
            ...acc,
            [patch.id]: patch.id.includes('farming_guild') ?
                ((playerStats?.Farming ?? 1) >= 65 && patch.default) :
                patch.default
        }), {})
    );
    const [activeAllotmentPatches, setActiveAllotmentPatches] = useStateWithLocalStorage('farming_active_allotment_patches',
        ALLOTMENT_PATCHES.reduce((acc, patch) => ({
            ...acc,
            [patch.id]: patch.id.includes('farming_guild') ?
                ((playerStats?.Farming ?? 1) >= 65 && patch.default) :
                patch.default
        }), {})
    );
    const [activeTreePatches, setActiveTreePatches] = useStateWithLocalStorage('farming_active_tree_patches',
        TREE_PATCHES.reduce((acc, patch) => ({
            ...acc,
            [patch.id]: patch.id.includes('farming_guild') ?
                ((playerStats?.Farming ?? 1) >= 65 && patch.default) :
                patch.default
        }), {})
    );
    const [activeBerryPatches, setActiveBerryPatches] = useStateWithLocalStorage('farming_active_berry_patches',
        BERRY_PATCHES.reduce((acc, patch) => ({
            ...acc,
            [patch.id]: patch.id.includes('farming_guild') ?
                ((playerStats?.Farming ?? 1) >= 65 && patch.default) :
                patch.default
        }), {})
    );
    const [showPatches, setShowPatches] = useState(false);

    const isLoading = !mapping || !Object.keys(mapping).length || !latest || !Object.keys(latest).length;
    const [farmingLevel, setFarmingLevel] = useState(playerStats?.Farming ?? 1);

    // Update farming level when playerStats changes
    useEffect(() => {
        if (playerStats?.Farming) {
            setFarmingLevel(playerStats.Farming);
        }
    }, [playerStats]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleHideUnqualifiedChange = (event) => {
        setHideUnqualified(event.target.checked);
    };

    const handlePatchToggle = (type, id) => (event) => {
        const setters = {
            herb: setActiveHerbPatches,
            allotment: setActiveAllotmentPatches,
            tree: setActiveTreePatches,
            berry: setActiveBerryPatches,
        };

        setters[type](prev => ({
            ...prev,
            [id]: event.target.checked,
        }));
    };

    const getActivePatchCount = useCallback((type) => {
        const patches = {
            herb: activeHerbPatches,
            allotment: activeAllotmentPatches,
            tree: activeTreePatches,
            berry: activeBerryPatches,
        };

        return Object.values(patches[type]).filter(Boolean).length;
    }, [activeHerbPatches, activeAllotmentPatches, activeTreePatches, activeBerryPatches]);

    const getActivePatchesSummary = useCallback((type) => {
        const patches = {
            herb: activeHerbPatches,
            allotment: activeAllotmentPatches,
            tree: activeTreePatches,
            berry: activeBerryPatches,
        };

        const activePatches = Object.entries(patches[type])
            .filter(([_, isActive]) => isActive)
            .map(([id]) => {
                const patchList = {
                    herb: HERB_PATCHES,
                    allotment: ALLOTMENT_PATCHES,
                    tree: TREE_PATCHES,
                    berry: BERRY_PATCHES,
                };
                return patchList[type].find(p => p.id === id)?.name;
            })
            .filter(Boolean);

        return activePatches.length > 0 ? activePatches.join(', ') : 'None selected';
    }, [activeHerbPatches, activeAllotmentPatches, activeTreePatches, activeBerryPatches]);

    const herbRows = useMemo(() => {
        if (isLoading) return [];

        const activePatchCount = getActivePatchCount('herb');

        const filteredHerbs = herbData.filter(herb => {
            if (!hideUnqualified) return true;
            return herb.levelRequired <= farmingLevel;
        });

        return filteredHerbs.map(herb => {
            const seedPrice = latest[herb.seedId]?.low || 0;
            const herbPrice = latest[herb.herbId]?.low || 0;
            const herbInfo = mapping[herb.herbId];

            const totalHerbsPerRun = Math.round(activePatchCount * AVG_HERBS_PER_PATCH);
            const totalSeedCost = Math.round(activePatchCount * seedPrice);
            const totalHerbValue = Math.round(totalHerbsPerRun * herbPrice);
            const profitPerRun = Math.round(totalHerbValue - totalSeedCost);

            return {
                ...herb,
                seedPrice,
                herbPrice,
                costPerRun: totalSeedCost,
                revenuePerRun: totalHerbValue,
                profitPerRun,
                icon: herbInfo?.icon || '',
                name: herbInfo?.name || herb.name,
            };
        });
    }, [mapping, latest, isLoading, hideUnqualified, farmingLevel, getActivePatchCount]);

    const allotmentRows = useMemo(() => {
        if (isLoading) return [];

        const activePatchCount = getActivePatchCount('allotment');

        return allotmentData
            .filter(crop => {
                if (!hideUnqualified) return true;
                return crop.levelRequired <= farmingLevel;
            })
            .map(crop => {
                const seedPrice = latest[crop.seedId]?.low || 0;
                const producePrice = latest[crop.produceId]?.low || 0;
                const produceInfo = mapping[crop.produceId];

                const totalProducePerRun = Math.round(activePatchCount * AVG_ALLOTMENTS_PER_PATCH);
                const totalSeedCost = Math.round(activePatchCount * seedPrice);
                const totalProduceValue = Math.round(totalProducePerRun * producePrice);
                const profitPerRun = Math.round(totalProduceValue - totalSeedCost);

                return {
                    ...crop,
                    seedPrice,
                    producePrice,
                    costPerRun: totalSeedCost,
                    revenuePerRun: totalProduceValue,
                    profitPerRun,
                    icon: produceInfo?.icon || '',
                    name: produceInfo?.name || crop.name,
                };
            });
    }, [mapping, latest, isLoading, hideUnqualified, farmingLevel, getActivePatchCount]);

    const treeRows = useMemo(() => {
        if (isLoading) return [];

        const activePatchCount = getActivePatchCount('tree');

        return treeData
            .filter(tree => {
                if (!hideUnqualified) return true;
                return tree.levelRequired <= farmingLevel;
            })
            .map(tree => {
                const seedPrice = latest[tree.seedId]?.low || 0;
                const producePrice = latest[tree.produceId]?.low || 0;
                const produceInfo = mapping[tree.produceId];

                const logsPerTree = Math.round(1 / tree.depletionChance);
                const totalProducePerRun = Math.round(activePatchCount * logsPerTree);
                const totalProduceValue = Math.round(totalProducePerRun * producePrice);

                return {
                    ...tree,
                    seedPrice,
                    producePrice,
                    costPerRun: 0,
                    revenuePerRun: totalProduceValue,
                    profitPerRun: totalProduceValue,
                    icon: produceInfo?.icon || '',
                    name: produceInfo?.name || tree.name,
                };
            });
    }, [mapping, latest, isLoading, hideUnqualified, farmingLevel, getActivePatchCount]);

    const berryRows = useMemo(() => {
        if (isLoading) return [];

        const activePatchCount = getActivePatchCount('berry');

        return berryData
            .filter(berry => {
                if (!hideUnqualified) return true;
                return berry.levelRequired <= farmingLevel;
            })
            .map(berry => {
                const seedPrice = latest[berry.seedId]?.low || 0;
                const producePrice = latest[berry.produceId]?.low || 0;
                const produceInfo = mapping[berry.produceId];

                const totalProducePerRun = Math.round(activePatchCount * 4);
                const totalProduceValue = Math.round(totalProducePerRun * producePrice);

                return {
                    ...berry,
                    seedPrice,
                    producePrice,
                    costPerRun: 0,
                    revenuePerRun: totalProduceValue,
                    profitPerRun: totalProduceValue,
                    icon: produceInfo?.icon || '',
                    name: produceInfo?.name || berry.name,
                };
            });
    }, [mapping, latest, isLoading, hideUnqualified, farmingLevel, getActivePatchCount]);

    const columns = [
        {
            field: 'name',
            headerName: activeTab === 0 ? 'Herb' : activeTab === 1 ? 'Crop' : activeTab === 2 ? 'Tree' : 'Berry',
            flex: 1,
            renderCell: ({row}) => {
                return <ItemRow
                    name={row.name}
                    icon={row.icon}
                    id={activeTab === 0 ? row.herbId : row.produceId}
                />;
            },
            minWidth: 200,
        },
        {
            field: 'levelRequired',
            headerName: 'Level',
            width: 100,
        },
        {
            field: 'seedPrice',
            headerName: 'Seed Price',
            renderCell: ({ value }) => numberFormat(value),
            width: 120,
        },
        {
            field: activeTab === 0 ? 'herbPrice' : 'producePrice',
            headerName: activeTab === 0 ? 'Herb Price' : 'Produce Price',
            renderCell: ({ value }) => numberFormat(value),
            width: 120,
        },
        ...(activeTab < 2 ? [{
            field: 'costPerRun',
            headerName: 'Cost/Run',
            renderCell: ({ value }) => numberFormat(value),
            width: 120,
        }] : []),
        ...(activeTab < 2 ? [{
            field: 'revenuePerRun',
            headerName: 'Revenue/Run',
            renderCell: ({ value }) => numberFormat(value),
            width: 120,
        }] : []),
        {
            field: activeTab < 2 ? 'profitPerRun' : 'revenuePerRun',
            headerName: activeTab < 2 ? 'Profit/Run' : 'Revenue/Run',
            renderCell: ({ value }) => numberFormat(value),
            width: 120,
        },
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <title>
                Farming - OldSchool Zone
            </title>
            <Container>
                <Typography variant="h1">
                    Farming
                </Typography>

                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'stretch', sm: 'center' }, gap: { xs: 1, sm: 0 } }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                flexGrow: 1,
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'nowrap',
                                    overflowX: 'auto',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    msOverflowStyle: 'none',
                                    scrollbarWidth: 'none'
                                }
                            }}
                        >
                            <Tab label="Herb" />
                            <Tab label="Allotment" />
                            <Tab label="Tree" />
                            <Tab label="Berry" />
                        </Tabs>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hideUnqualified}
                                    onChange={handleHideUnqualifiedChange}
                                />
                            }
                            label={`Hide unqualified`}
                            sx={{
                                ml: { xs: 0, sm: 2 },
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '0.875rem'
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper sx={{ mb: 2, p: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="h6">
                            Active Patches
                        </Typography>
                        <IconButton
                            onClick={() => setShowPatches(!showPatches)}
                            sx={{
                                transform: showPatches ? 'rotate(180deg)' : 'none',
                                transition: 'transform 0.2s',
                            }}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {getActivePatchesSummary(
                            activeTab === 0 ? 'herb' :
                            activeTab === 1 ? 'allotment' :
                            activeTab === 2 ? 'tree' :
                            'berry'
                        )}
                    </Typography>
                    <Collapse in={showPatches}>
                        <Grid container spacing={1}>
                            {(activeTab === 0 ? HERB_PATCHES :
                              activeTab === 1 ? ALLOTMENT_PATCHES :
                              activeTab === 2 ? TREE_PATCHES :
                              BERRY_PATCHES).map(patch => (
                                <Grid item xs={6} sm={4} md={3} key={patch.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    activeTab === 0 ? activeHerbPatches[patch.id] :
                                                    activeTab === 1 ? activeAllotmentPatches[patch.id] :
                                                    activeTab === 2 ? activeTreePatches[patch.id] :
                                                    activeBerryPatches[patch.id]
                                                }
                                                onChange={handlePatchToggle(
                                                    activeTab === 0 ? 'herb' :
                                                    activeTab === 1 ? 'allotment' :
                                                    activeTab === 2 ? 'tree' :
                                                    'berry',
                                                    patch.id
                                                )}
                                            />
                                        }
                                        label={patch.name}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.875rem'
                                            }
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Collapse>
                </Paper>

                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                        mb: 2,
                    }}
                >
                    {activeTab === 0 ? (
                        `Calculations assume ${getActivePatchCount('herb')} herb patches and an average of ${AVG_HERBS_PER_PATCH} herbs per patch with Magic Secateurs.`
                    ) : activeTab === 1 ? (
                        `Calculations assume ${getActivePatchCount('allotment')} allotment patches and an average of ${AVG_ALLOTMENTS_PER_PATCH} produce per patch with Ultracompost.`
                    ) : activeTab === 2 ? (
                        `Calculations assume ${getActivePatchCount('tree')} tree patches. Regular trees have a 1/8 chance to deplete per log, while Redwood has a 1/11 chance.`
                    ) : (
                        `Calculations assume ${getActivePatchCount('berry')} berry bush patches with an average of 4 berries per bush.`
                    )}
                </Typography>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        density="standard"
                        rows={
                            activeTab === 0 ? herbRows :
                            activeTab === 1 ? allotmentRows :
                            activeTab === 2 ? treeRows :
                            berryRows
                        }
                        columns={columns}
                        initialState={{
                            sorting: {
                                sortModel: [{
                                    field: 'profitPerRun',
                                    sort: 'desc',
                                }],
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                    />
                )}
            </Container>
        </Box>
    );
}

export default Farming;
