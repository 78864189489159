import {
    useState,
    useMemo,
    useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import StickyTable from '../components/StickyTable';
import InfoAlert from '../components/InfoAlert.js';

import numberFormat from '../modules/number-format.mjs';

import '../App.css';

function MonsterProfits({mapping, latest, filter, playerStats}) {
    const [hideNonCombat, setHideNonCombat] = useState(true);
    const [hideUnqualified, setHideUnqualified] = useState(true);
    const [maxCombatLevel, setMaxCombatLevel] = useState(0);
    const [hideSuperior, setHideSuperior] = useState(true);
    const [monsterData, setMonsterData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMonsterData = async () => {
            try {
                const response = await fetch('/data/monsters.json');
                if (!response.ok) {
                    throw new Error('Failed to load monster data');
                }
                const data = await response.json();
                setMonsterData(data);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchMonsterData();
    }, []);

    const rows = useMemo(() => {
        if (!monsterData) {
            return [];
        }

        const itemMap = {};
        let monsterRows = [];

        for(const itemId in mapping){
            itemMap[mapping[itemId].name.toLowerCase()] = itemId;
        }

        // console.log(maxCombatLevel);

        for (const result of monsterData) {
            const monsterData = {
                id: result.name,
                ...result,
            };

            let totalLootValue = 0;

            if(hideNonCombat && (monsterData.combatLevel === 0 || !monsterData.combatLevel)){
                // console.log(`Hiding ${monsterData.name} because it is not combat`);
                continue;
            }

            if(hideUnqualified && monsterData.slayerLevel > playerStats['Slayer']){
                continue;
            }

            if(maxCombatLevel && maxCombatLevel < monsterData.combatLevel){
                continue;
            }

            if(filter && monsterData.name.toLowerCase().indexOf(filter.toLowerCase()) === -1){
                continue;
            }

            if(hideSuperior && monsterData.superior){
                continue;
            }

            for(const drop of monsterData.drops){
                let item = latest[itemMap[drop.item.toLowerCase()]];

                if(!item && drop.item !== 'Coins'){
                    // console.log(`Could not find item for ${drop.item}`);

                    continue;
                }

                if(!drop.quantity){
                    // console.log(`Could not find quantity for ${drop.item}`);

                    continue;
                }

                if(drop.item === 'Coins'){
                    item = {
                        low: 1,
                    }
                }

                totalLootValue = totalLootValue + Math.floor(item.low * drop.quantity);

            }

            if(!totalLootValue){
                continue;
            }

            monsterData.lootValue = totalLootValue;
            monsterData.lootRatio = Math.floor(totalLootValue / monsterData.combatLevel);

            monsterRows.push(monsterData);
        }

        return monsterRows;
    }, [mapping, latest, hideNonCombat, hideUnqualified, maxCombatLevel, playerStats, filter, hideSuperior, monsterData]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            renderCell: ({row}) => {
                // console.log(row);
                return <a
                    href={row.link}
                >
                    {row.name}
                </a>;
            },
            minWidth: 200,
        },
        {
            field: 'lootValue',
            headerName: 'Avg. Loot',
            renderCell: ({ value }) => numberFormat(value),
            // width: 150,
        },
        {
            field: 'lootRatio',
            headerName: 'Loot ratio',
            renderCell: ({ value }) => numberFormat(value),
            // width: 150,
        },
        {
            field: 'combatLevel',
            headerName: 'Combat Level',
            renderCell: ({ value }) => numberFormat(value),
            // width: 150,
        },
        {
            field: 'slayerLevel',
            headerName: 'Slayer Level',
            renderCell: ({ value }) => numberFormat(value) || '',
            // width: 150,
        },
    ];

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Monster Profits - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant="h1"
            >
                Monster Profits
            </Typography>
            <InfoAlert>
                Calculate potential profits from killing different monsters based on current Grand Exchange prices and drop rates.
                The calculator takes into account your combat stats to show which monsters you can effectively fight.
            </InfoAlert>
            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Alert
                        severity="warning"
                        sx={{
                            mb: 2,
                            backgroundColor: 'transparent',
                            border: { xs: 'none', sm: '1px solid rgba(255, 255, 255, 0.12)' },
                            color: 'text.primary',
                            padding: { xs: 0, sm: '6px 16px' },
                            '& .MuiAlert-icon': {
                                color: '#ff9800',
                                display: { xs: 'none', sm: 'block' }
                            },
                            '& .MuiAlert-message': {
                                padding: { xs: 0, sm: '8px 0' }
                            }
                        }}
                    >
                        All of these prices are rough estimates and might be <i>very</i> off. Always verify with the wiki!
                    </Alert>
                    <FormGroup>
                        <Stack
                            direction="row"
                            flexWrap={'wrap'}
                        >
                            <FormControlLabel control={
                                <Checkbox
                                    checked={hideNonCombat}
                                    label="Hide non-combat things"
                                    onChange={(event) => {
                                        setHideNonCombat(event.target.checked);
                                    }}
                                />
                            } label="Hide non-combat" />
                            <FormControlLabel control={
                                <Checkbox
                                    checked={hideUnqualified}
                                    label="Hide unqualified"
                                    onChange={(event) => {
                                        setHideUnqualified(event.target.checked);
                                    }}
                                />
                            } label="Hide unqualified" />
                            <FormControlLabel control={
                                <Checkbox
                                    checked={hideSuperior}
                                    label="Hide superior"
                                    onChange={(event) => {
                                        setHideSuperior(event.target.checked);
                                    }}
                                />
                            } label="Hide superior" />
                            <FormControlLabel control={
                                <TextField
                                    label="Max combat level"
                                    onChange={(event) => {
                                        setMaxCombatLevel(event.target.value);
                                    }}
                                    type="number"
                                    size="small"
                                />
                            }/>
                        </Stack>
                    </FormGroup>
                    <StickyTable
                        density="standard"
                        rows={rows}
                        columns={columns}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                            sorting: {
                                sortModel: [{
                                    field: 'lootRatio',
                                    sort: 'desc',
                                }],
                            },
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                    />
                </>
            )}
        </Container>
    </Box>;
}

export default MonsterProfits;
