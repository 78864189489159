import {
    useMemo,
    useState,
    useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { useSearchParams } from 'react-router-dom';

import numberFormat from '../modules/number-format.mjs';
import calculatedItemPrice from '../modules/calculated-item-price.mjs';
import InfoAlert from '../components/InfoAlert.js';

import moneyMaking from '../data/money-making.json';
import customMoneyMaking from '../data/custom-money-making.json';

import '../App.css';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

function MoneyMaking({filter, playerStats, mapping, onPlayerNameChange}) {
    const [searchParams] = useSearchParams();
    const [hideUnqualified, setHideUnqualified] = useState(true);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [intensityList, setIntensityList] = useState([]);
    const [intensityFilter, setIntensityFilter] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [skillFilter, setSkillFilter] = useState([]);

    useEffect(() => {
        const character = searchParams.get('character');
        if (character && onPlayerNameChange) {
            onPlayerNameChange(character);
        }
    }, [searchParams, onPlayerNameChange]);

    useEffect(() => {
        let defaultCategoryFilter = [];
        let defaultIntensityList = [];
        let defaultSkillList = [];

        for(const result of moneyMaking){
            let mainCategory = result.category.split('/')[0];
            // Merge Crafting into Skilling
            if (mainCategory === 'Crafting') {
                mainCategory = 'Skilling';
            }
            defaultCategoryFilter.push(mainCategory);
            defaultIntensityList.push(result.intensity);

            // Extract skill from category if it's a skilling method
            if (mainCategory === 'Skilling' && result.category.includes('/')) {
                const skill = result.category.split('/')[1];
                if (skill) {
                    defaultSkillList.push(skill);
                }
            }
        }

        for(const result of customMoneyMaking){
            let mainCategory = result.category.split('/')[0];
            // Merge Crafting into Skilling
            if (mainCategory === 'Crafting') {
                mainCategory = 'Skilling';
            }
            defaultCategoryFilter.push(mainCategory);
            defaultIntensityList.push(result.intensity);

            // Extract skill from category if it's a skilling method
            if (mainCategory === 'Skilling' && result.category.includes('/')) {
                const skill = result.category.split('/')[1];
                if (skill) {
                    defaultSkillList.push(skill);
                }
            }
        }

        defaultCategoryFilter = [...new Set(defaultCategoryFilter)].sort();
        defaultIntensityList = [...new Set(defaultIntensityList)];
        defaultSkillList = [...new Set(defaultSkillList)].sort();

        setCategoryList(defaultCategoryFilter);
        setIntensityList(defaultIntensityList);
        setSkillList(defaultSkillList);
    }, []);

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setCategoryFilter(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleIntensityChange = (event) => {
        const {
            target: { value },
        } = event;
        setIntensityFilter(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSkillChange = (event) => {
        const {
            target: { value },
        } = event;
        setSkillFilter(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const rows = useMemo(() => {
        let returnRows = [];
        console.log(categoryFilter);
        let parsedCategoryFilter = [];

        if(typeof parsedCategoryFilter === 'string'){
            parsedCategoryFilter = categoryFilter?.split(',');
        }

        for (const result of moneyMaking) {
            const hourlyCost = result.hourlyInput.reduce((acc, item) => {
                if(item.id === 'coins'){
                    return acc + item.count;
                }

                if(typeof item.id === 'string'){
                    const singleItemPrice = calculatedItemPrice(item.id, mapping);

                    if(singleItemPrice){
                        return acc + (singleItemPrice * item.count);
                    }
                }

                if(!mapping[item.id]){
                    return acc;
                }

                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0);

            const hourlyProfit = result.hourlyOutput.reduce((acc, item) => {
                if(item.id === 'coins'){
                    return acc + item.count;
                }

                if(!mapping[item.id]){
                    return acc;
                }

                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0) - hourlyCost;

            if(hourlyProfit){
                result.hourlyProfitWiki = Math.floor(hourlyProfit);
                result.hourlyProfit = Math.floor(hourlyProfit);
            }

            returnRows.push({
                id: result.methodName,
                ...result,
            });
        }

        for(const result of customMoneyMaking){
            const hourlyCost = result.hourlyInput.reduce((acc, item) => {
                if(item.id === 'coins'){
                    return acc + item.count;
                }

                if(typeof item.id === 'string'){
                    const singleItemPrice = calculatedItemPrice(item.id, mapping);

                    if(singleItemPrice){
                        return acc + (singleItemPrice * item.count);
                    }
                }

                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0);

            const hourlyProfit = result.hourlyOutput.reduce((acc, item) => {
                return acc + (mapping[item.id]?.lowestPrice.cost * item.count);
            }, 0) - hourlyCost;

            returnRows.push({
                id: result.methodName,
                hourlyProfit,
                ...result,
            });
        }

        returnRows = returnRows.filter((row) => {
            for (const requirement in row.skills) {
                // Skip non-skill requirements if hideUnqualified is true since we can't verify them
                if ((requirement === 'Quests' || requirement === 'Achievement Diary' || requirement === 'Combat level') && hideUnqualified) {
                    return false;
                }

                // Check skill requirements
                if (requirement !== 'Quests' && requirement !== 'Achievement Diary' && requirement !== 'Combat level') {
                    const requiredLevel = parseInt(row.skills[requirement]);
                    if (!isNaN(requiredLevel) && playerStats[requirement] < requiredLevel && hideUnqualified) {
                        return false;
                    }
                }
            }

            if(filter && !row.methodName.toLowerCase().includes(filter.toLowerCase())){
                return false;
            }

            if(categoryFilter && categoryFilter.length > 0) {
                let mainCategory = row.category.split('/')[0];
                // Merge Crafting into Skilling in filter check
                if (mainCategory === 'Crafting') {
                    mainCategory = 'Skilling';
                }
                if(!categoryFilter.includes(mainCategory)) {
                    return false;
                }
            }

            if(intensityFilter && intensityFilter.length > 0 && !intensityFilter.includes(row.intensity)){
                return false;
            }

            if(skillFilter && skillFilter.length > 0) {
                const categorySkill = row.category.split('/')[1];
                if (!categorySkill || !skillFilter.includes(categorySkill)) {
                    return false;
                }
            }

            return true;
        });

        return returnRows;
    }, [playerStats, hideUnqualified, filter, mapping, categoryFilter, intensityFilter, skillFilter]);

    const calculateRowHeight = (params) => {
        // Count only skill requirements
        const skillCount = Object.keys(params.model.skills || {}).filter(skill => {
            const lowerSkill = skill.toLowerCase();
            return !lowerSkill.includes('quest') &&
                   !lowerSkill.includes('achievement') &&
                   !lowerSkill.includes('diary') &&
                   !lowerSkill.includes('combat level');
        }).length;
        return (skillCount || 1) * 25 + (16 * params.densityFactor);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        {
            field: 'methodName',
            flex: 1,
            headerName: 'Name',
            renderCell: ({ value }) => {
                const itemData = rows.find((row) => row.methodName === value);
                return <a
                    href={`${itemData?.methodLink.includes('https') ? '' : 'https://oldschool.runescape.wiki'}${itemData?.methodLink}`}
                >
                    {value}
                </a>;
            },
            minWidth: 200,
        },
        {
            field: 'category',
            headerName: 'Category',
            width: 150,
            renderCell: ({ value }) => {
                const mainCategory = value.split('/')[0];
                const subCategory = value.split('/')[1];
                return <div>
                    <div>{mainCategory}</div>
                    {subCategory && <div style={{ fontSize: '0.8em', color: 'gray' }}>{subCategory}</div>}
                </div>;
            }
        },
        {
            field: 'skill',
            headerName: 'Skill',
            width: 100,
            renderCell: ({ row }) => {
                const mainCategory = row.category.split('/')[0];
                if (mainCategory === 'Skilling' || mainCategory === 'Crafting') {
                    return row.category.split('/')[1] || '';
                }
                return '';
            }
        },
        {
            field: 'intensity',
            headerName: 'Intensity',
            width: 100,
        },
        {
            field: 'hourlyProfit',
            headerName: 'Profit / Hour',
            renderCell: ({ value }) => numberFormat(value),
            width: 120,
        },
        {
            field: 'skills',
            headerName: 'Requirements',
            renderCell: ({ value }) => {
                const skillRequirements = [];
                for(const skill in value){
                    // Skip non-skill requirements using case-insensitive check
                    const lowerSkill = skill.toLowerCase();
                    if (lowerSkill.includes('quest') ||
                        lowerSkill.includes('achievement') ||
                        lowerSkill.includes('diary') ||
                        lowerSkill.includes('combat level')) {
                        continue;
                    }

                    let isQualifiedClass = 'skill-ok';
                    const requiredLevel = parseInt(value[skill]);
                    if (!isNaN(requiredLevel) && playerStats[skill] < requiredLevel) {
                        isQualifiedClass = 'skill-not-ok';
                    }

                    skillRequirements.push(<div
                        key={skill}
                    >
                        <span
                            className={isQualifiedClass}
                        >
                            {skill}: {value[skill]}
                        </span>
                    </div>);
                };

                return <div
                    className='field-list-wrapper'
                >
                    {skillRequirements}
                </div>;
            },
            width: 200,
        },
    ];

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Money Making - OldSchool Zone
        </title>
        <Container>
            <Typography
                variant="h1"
            >
                {'Money Making'}
            </Typography>
            <InfoAlert>
                Explore various money-making methods in Old School RuneScape. The calculator shows potential earnings based on current Grand Exchange
                prices and your skill levels. Methods you can't access yet are automatically filtered out based on your RuneLite stats.
            </InfoAlert>
            <FormGroup>
                <Stack
                    direction="row"
                    flexWrap={'wrap'}
                    sx={{ mb: 2 }}
                >
                    <FormControl size="small" sx={{ mr: { xs: 0.5, sm: 1 }, mb: 1, width: { xs: 'calc(50% - 4px)', sm: 200 } }}>
                        <InputLabel id="category-checkbox-label">
                            Category
                        </InputLabel>
                        <Select
                            labelId="category-checkbox-label"
                            id="category-checkbox"
                            multiple
                            size="small"
                            value={categoryFilter}
                            onChange={handleCategoryChange}
                            input={<OutlinedInput label="Category" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                        {categoryList.map((value) => (
                            <MenuItem key={value} value={value}>
                                <Checkbox size="small" checked={categoryFilter.includes(value)} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControl size="small" sx={{ mr: { xs: 0, sm: 1 }, mb: 1, width: { xs: 'calc(50% - 4px)', sm: 200 } }}>
                        <InputLabel id="intensity-checkbox-label">
                            Intensity
                        </InputLabel>
                        <Select
                            labelId="intensity-checkbox-label"
                            id="intensity-checkbox"
                            multiple
                            size="small"
                            value={intensityFilter}
                            onChange={handleIntensityChange}
                            input={<OutlinedInput label="Intensity" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                        {intensityList.map((value) => (
                            <MenuItem key={value} value={value}>
                                <Checkbox size="small" checked={intensityFilter.includes(value)} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControl size="small" sx={{ mr: { xs: 0.5, sm: 1 }, mb: 1, width: { xs: 'calc(50% - 4px)', sm: 200 } }}>
                        <InputLabel id="skill-checkbox-label">
                            Skills
                        </InputLabel>
                        <Select
                            labelId="skill-checkbox-label"
                            id="skill-checkbox"
                            multiple
                            size="small"
                            value={skillFilter}
                            onChange={handleSkillChange}
                            input={<OutlinedInput label="Skills" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                        {skillList.map((value) => (
                            <MenuItem key={value} value={value}>
                                <Checkbox size="small" checked={skillFilter.includes(value)} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        sx={{ mb: 1, width: { xs: 'calc(50% - 4px)', sm: 'auto' } }}
                        control={
                            <Checkbox
                                size="small"
                                checked={hideUnqualified}
                                onChange={(event) => {
                                    setHideUnqualified(event.target.checked);
                                }}
                            />
                        }
                        label="Hide unqualified"
                    />
                </Stack>
            </FormGroup>
            <DataGrid
                density="standard"
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [{
                            field: 'hourlyProfit',
                            sort: 'desc',
                        }],
                    },
                }}
                getRowHeight={calculateRowHeight}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />
        </Container>
    </Box>;
}

export default MoneyMaking;

