import {
    // useState,
    useMemo,
    // useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import StickyTable from '../components/StickyTable';
import ItemRow from '../components/ItemRow.js';
import InfoAlert from '../components/InfoAlert.js';

import numberFormat from '../modules/number-format.mjs';

import '../App.css';

const unlocks = [
    { name: 'Air rune', multiplier: 1, level: 1 },
    { name: 'Air rune', multiplier: 2, level: 11 },
    { name: 'Air rune', multiplier: 3, level: 22 },
    { name: 'Air rune', multiplier: 4, level: 33 },
    { name: 'Air rune', multiplier: 5, level: 44 },
    { name: 'Air rune', multiplier: 6, level: 55 },
    { name: 'Air rune', multiplier: 7, level: 66 },
    { name: 'Air rune', multiplier: 8, level: 77 },
    { name: 'Air rune', multiplier: 9, level: 88 },
    { name: 'Air rune', multiplier: 10, level: 99 },
    { name: 'Mind rune', multiplier: 1, level: 2 },
    { name: 'Mind rune', multiplier: 2, level: 14 },
    { name: 'Mind rune', multiplier: 3, level: 28 },
    { name: 'Mind rune', multiplier: 4, level: 42 },
    { name: 'Mind rune', multiplier: 5, level: 56 },
    { name: 'Mind rune', multiplier: 6, level: 70 },
    { name: 'Mind rune', multiplier: 7, level: 84 },
    { name: 'Mind rune', multiplier: 8, level: 98 },
    { name: 'Water rune', multiplier: 1, level: 5 },
    { name: 'Water rune', multiplier: 2, level: 19 },
    { name: 'Water rune', multiplier: 3, level: 38 },
    { name: 'Water rune', multiplier: 4, level: 57 },
    { name: 'Water rune', multiplier: 5, level: 76 },
    { name: 'Water rune', multiplier: 6, level: 95 },
    { name: 'Earth rune', multiplier: 1, level: 9 },
    { name: 'Earth rune', multiplier: 2, level: 26 },
    { name: 'Earth rune', multiplier: 3, level: 52 },
    { name: 'Earth rune', multiplier: 4, level: 78 },
    { name: 'Earth rune', multiplier: 5, level: 104 },
    { name: 'Fire rune', multiplier: 1, level: 14 },
    { name: 'Fire rune', multiplier: 2, level: 35 },
    { name: 'Fire rune', multiplier: 3, level: 70 },
    { name: 'Body rune', multiplier: 1, level: 20 },
    { name: 'Body rune', multiplier: 2, level: 46 },
    { name: 'Body rune', multiplier: 3, level: 92 },
    { name: 'Cosmic rune', multiplier: 1, level: 27 },
    { name: 'Cosmic rune', multiplier: 2, level: 59 },
    { name: 'Chaos rune', multiplier: 1, level: 35 },
    { name: 'Chaos rune', multiplier: 2, level: 74 },
    { name: 'Astral rune', multiplier: 1, level: 40 },
    { name: 'Astral rune', multiplier: 2, level: 82 },
    { name: 'Nature rune', multiplier: 1, level: 44 },
    { name: 'Nature rune', multiplier: 2, level: 91 },
    { name: 'Law rune', multiplier: 1, level: 54 },
    { name: 'Law rune', multiplier: 2, level: 95 },
    { name: 'Death rune', multiplier: 1, level: 65 },
    { name: 'Death rune', multiplier: 2, level: 99 }
];

function RunecraftProfit({mapping, latest, filter, playerStats}) {
    const rows = useMemo(() => {
        let profitRows = [];
        const itemMap = {};

        for(const itemId in mapping){
            itemMap[mapping[itemId].name.toLowerCase()] = itemId;
        }

        for(const unlock of unlocks){
            const item = mapping[itemMap[unlock.name.toLowerCase()]];

            if(!item){
                // console.error(`Missing item for ${unlock.name}`);
                continue;
            }

            const profit = item.lowestPrice.cost * unlock.multiplier;

            profitRows.push({
                id: `${unlock.name} x${unlock.multiplier}`,
                name: unlock.name,
                count: unlock.multiplier,
                craftValue: profit,
                levelReq: unlock.level,
                doable: playerStats.Runecraft >= unlock.level,
                type: unlock.name,
                icon: item.icon,
                itemId: item.id,
            });
        }

        profitRows = profitRows
            .filter((row) => {
                return row.doable;
            })
            .sort((a, b) => b.craftValue - a.craftValue);

        let returnRows = [];
        let returnMap = {};

        for(const profitRow of profitRows){
            if(!returnMap[profitRow.type]){
                returnMap[profitRow.type] = true;

                returnRows.push(profitRow);
            }
        }

        return returnRows;
    }, [mapping, playerStats]);

    console.log(rows);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            // width: 70,
        },
        // {
        //     field: 'name',
        //     flex: 1,
        //     headerName: 'Name',
        //     renderCell: ({row}) => {
        //         // console.log(row);
        //         return <a
        //             href={row.link}
        //         >
        //             {row.name}
        //         </a>;
        //     },
        //     minWidth: 200,
        // },
        {
            field: 'name',
            flex: 1,
            headerName: 'Name',
            renderCell: ({row}) => {
                return <ItemRow
                    name={row.name}
                    icon={row.icon}
                    id={row.itemId}
                />;
            },
            minWidth: 200,
        },
        {
            field: 'count',
            headerName: 'Count',
            renderCell: ({ value }) => numberFormat(value),
            width: 150,
        },
        {
            field: 'levelReq',
            headerName: 'Level requirement',
            renderCell: ({ value }) => numberFormat(value),
            width: 150,
        },
        {
            field: 'craftValue',
            headerName: 'Profit',
            renderCell: ({ value }) => numberFormat(value),
            width: 150,
        },
        // {
        //     field: 'doable',
        //     headerName: 'Doable',
        //     // renderCell: ({ value }) => numberFormat(value),
        //     width: 150,
        // },
    ];

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Runecraft Profit - OldSchool Zone
        </title>
        <Container>
            <Typography variant="h1">
                {'Runecraft Profit'}
            </Typography>
            <InfoAlert>
                Compare profits from different Runecrafting methods. The calculator shows potential earnings based on current Grand Exchange
                prices and your Runecrafting level. Methods you can't access yet are automatically filtered out based on your RuneLite stats.
            </InfoAlert>
            <StickyTable
                density="standard"
                rows={rows}
                columns={columns}
                // getRowHeight={() => 'auto'}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    sorting: {
                        sortModel: [{
                            field: 'profit',
                            sort: 'desc',
                        }],
                    },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                hideFooter
            />
        </Container>
    </Box>;
}

export default RunecraftProfit;
