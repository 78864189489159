export default function getLowestPrice({itemId, latest, storesData}) {
    let lowestPrice = Infinity;
    let lowestPriceType = '';

    // Check store prices
    if (storesData) {
        const storeItem = storesData.find((store) => store.id === itemId);
        if (storeItem?.sellPrice && storeItem.sellPrice < lowestPrice) {
            lowestPrice = storeItem.sellPrice;
            lowestPriceType = 'store';
        }
    }

    // Check GE prices
    if (latest?.[itemId]?.low && latest[itemId].low < lowestPrice) {
        lowestPrice = latest[itemId].low;
        lowestPriceType = 'ge';
    }

    if (lowestPrice === Infinity) {
        return null;
    }

    return {
        type: lowestPriceType,
        cost: lowestPrice
    };
}