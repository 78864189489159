const calculators = {
    'crystal-shard': (mapping) => {
        return Math.floor(mapping[23959]?.lowestPrice.cost / 150);
    },
    'crystal-dust': (mapping) => {
        return Math.floor(calculators['crystal-shard'](mapping) / 10);
    },
    'twisted-extract': (mapping) => {
        return 6000;
    },
    'scarred-extract': (mapping) => {
        return 24000;
    },
    'mangled-extract': (mapping) => {
        return 12000;
    },
    'warped-extract': (mapping) => {
        return 1250;
    },
};

const getCalculatedPrice = (itemName, mapping) => {
    if(!calculators[itemName]){
        console.log(`Missing method for ${itemName}`);

        return false;
    }

    return calculators[itemName](mapping);
};

const availableItems = Object.keys(calculators);

export default getCalculatedPrice;

export {
    availableItems,
}