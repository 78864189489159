import {
    useMemo,
    useState,
    useRef,
    useEffect,
} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

import numberFormat from '../modules/number-format.mjs';
import InfoAlert from '../components/InfoAlert.js';

import '../App.css';

const skills = [
    'Hitpoints', 'Ranged', 'Prayer', 'Magic',
    'Cooking', 'Woodcutting', 'Fletching', 'Fishing', 'Firemaking', 'Crafting',
    'Smithing', 'Mining', 'Herblore', 'Agility', 'Thieving', 'Slayer',
    'Farming', 'Runecraft', 'Hunter', 'Construction'
].sort();

const calculate = (targetLevel) => {
    let previousLevel = targetLevel - 1;
    return 1/4 * Math.floor(previousLevel + 300 * (2 ** (previousLevel / 7)));
};

function LevelCalculator({mapping, latest, playerStats}) {
    const [selectedSkill, setSelectedSkill] = useState('');
    const [currentLevel, setCurrentLevel] = useState(1);
    const [targetLevel, setTargetLevel] = useState(99);
    const [experiencePerAction, setExperiencePerAction] = useState(1);
    const [costPerAction, setCostPerAction] = useState(1);
    const itemRef = useRef(null);

    useEffect(() => {
        if (selectedSkill && playerStats && playerStats[selectedSkill]) {
            setCurrentLevel(playerStats[selectedSkill]);
            setTargetLevel(Math.min(99, playerStats[selectedSkill] + 1));
        }
    }, [selectedSkill, playerStats]);

    const availableItems = useMemo(() => {
        const availableItems = [];
        for (const [key, value] of Object.entries(mapping)) {
            if (value !== null) {
                availableItems.push(mapping[key].name);
            }
        }
        return availableItems.sort();
    }, [mapping]);

    const expNeeded = useMemo(() => {
        let currentExp = 0;
        for(let i = 1; i <= currentLevel; i = i + 1 ) {
            currentExp = currentExp + calculate(i);
        }

        let targetExp = 0;
        for(let i = 1; i <= targetLevel; i = i + 1 ) {
            targetExp = targetExp + calculate(i);
        }

        return targetExp - currentExp;
    }, [currentLevel, targetLevel]);

    const handleCurrentLevelChange = (event) => {
        const value = Number(event.target.value);
        if (value >= 1 && value <= 99) {
            setCurrentLevel(value);
            if (value >= targetLevel) {
                setTargetLevel(value + 1);
            }
        }
    };

    const handleTargetLevelChange = (event) => {
        const value = Number(event.target.value);
        if (value >= 1 && value <= 99) {
            setTargetLevel(value);
            if (value <= currentLevel) {
                setCurrentLevel(value - 1);
            }
        }
    };

    const handleExpPerActionChange = (event) => {
        const value = Number(event.target.value);
        if (value >= 0) {
            setExperiencePerAction(value);
        }
    };

    const handleCostPerActionChange = (event) => {
        const value = Number(event.target.value);
        if (value >= 0) {
            setCostPerAction(value);
        }
    };

    return <Box sx={{ flexGrow: 1 }}>
        <title>
            Level Calculator - OldSchool Zone
        </title>
        <Container>
            <Typography variant="h1">
                Level Calculator
            </Typography>
            <InfoAlert>
                Calculate the experience and materials needed to reach your target level. Enter the experience gained per action and material costs to see total requirements.
            </InfoAlert>
            <Card variant="outlined" sx={{ mt: 3 }}>
                <CardContent>
                    <Stack spacing={4}>
                        <FormControl fullWidth>
                            <InputLabel id="skill-select-label">Skill</InputLabel>
                            <Select
                                labelId="skill-select-label"
                                value={selectedSkill}
                                label="Skill"
                                onChange={(e) => setSelectedSkill(e.target.value)}
                                size="small"
                            >
                                {skills.map((skill) => (
                                    <MenuItem key={skill} value={skill}>{skill}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Stack direction={'row'} spacing={2}>
                            <TextField
                                type="number"
                                name="current-level"
                                placeholder="Current Level"
                                label="Current Level"
                                value={currentLevel || ''}
                                onChange={handleCurrentLevelChange}
                                sx={{ width: '50%' }}
                                size="small"
                                inputProps={{ min: 1, max: 99 }}
                                error={currentLevel < 1 || currentLevel > 99}
                                helperText={currentLevel < 1 || currentLevel > 99 ? "Level must be between 1-99" : ""}
                            />
                            <TextField
                                type="number"
                                name="target-level"
                                placeholder="Target Level"
                                label="Target Level"
                                value={targetLevel || ''}
                                onChange={handleTargetLevelChange}
                                sx={{ width: '50%' }}
                                size="small"
                                inputProps={{ min: 1, max: 99 }}
                                error={targetLevel < 1 || targetLevel > 99}
                                helperText={targetLevel < 1 || targetLevel > 99 ? "Level must be between 1-99" : ""}
                            />
                        </Stack>

                        <Box>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h6" gutterBottom>
                                    Experience Needed
                                </Typography>
                                <Tooltip title="The total amount of experience points needed to reach your target level from your current level">
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Typography variant="h5" color="primary">
                                {numberFormat(Math.round(expNeeded))} exp
                            </Typography>
                        </Box>

                        <Box>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h6" gutterBottom>
                                    Training Method
                                </Typography>
                                <Tooltip title="Enter the experience gained per action and the cost of materials needed for each action">
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Stack spacing={3}>
                                <TextField
                                    type="number"
                                    name="experience-per-action"
                                    placeholder="Experience per Action"
                                    label="Experience per Action"
                                    value={experiencePerAction || ''}
                                    onChange={handleExpPerActionChange}
                                    size="small"
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    error={experiencePerAction < 0}
                                    helperText={experiencePerAction < 0 ? "Experience cannot be negative" : ""}
                                />
                                <Stack direction={'row'} spacing={2}>
                                    <TextField
                                        type="number"
                                        name="cost-per-action"
                                        placeholder="Cost per Action"
                                        label="Cost per Action"
                                        value={costPerAction || ''}
                                        onChange={handleCostPerActionChange}
                                        sx={{ width: '50%' }}
                                        size="small"
                                        inputProps={{ min: 0 }}
                                        error={costPerAction < 0}
                                        helperText={costPerAction < 0 ? "Cost cannot be negative" : ""}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        options={availableItems}
                                        onChange={(event, value) => {
                                            const itemId = Object.values(mapping).find((item) => item.name === value)?.id;
                                            if(!itemId) {
                                                return true;
                                            }
                                            setCostPerAction(latest[itemId].low);
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            inputRef={itemRef}
                                            label="Select Item"
                                            size="small"
                                        />}
                                        sx={{ width: '50%' }}
                                        size="small"
                                    />
                                </Stack>
                            </Stack>
                        </Box>

                        <Box>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h6" gutterBottom>
                                    Summary
                                </Typography>
                                <Tooltip title="Total number of actions needed and the estimated cost to reach your target level">
                                    <IconButton size="small">
                                        <InfoIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant="body1">
                                    Actions needed: <strong>{numberFormat(Math.round(expNeeded / experiencePerAction))}</strong>
                                </Typography>
                                <Typography variant="body1">
                                    Total cost: <strong>{numberFormat(Math.round(expNeeded / experiencePerAction) * costPerAction)} gp</strong>
                                </Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    </Box>;
}

export default LevelCalculator;
