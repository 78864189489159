import {
    useRef,
} from 'react';

import Grid from '@mui/material/Grid2'; // Grid version 2
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

// import useStateWithLocalStorage from '../hooks/useStateWithLocalStorage';

let skillOrder = [
    'Attack',
    'Hitpoints',
    'Mining',
    'Strength',
    'Agility',
    'Smithing',
    'Defence',
    'Herblore',
    'Fishing',
    'Ranged',
    'Thieving',
    'Cooking',
    'Prayer',
    'Crafting',
    'Firemaking',
    'Magic',
    'Fletching',
    'Woodcutting',
    'Runecraft',
    'Slayer',
    'Farming',
    'Construction',
    'Hunter',
    'Combat level',
];

export default function Skills({playerName, playerStats, playerDataError, setPlayerName}) {
    const inputRef = useRef(null);

    return (
        <Box>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    size = {{
                        xs: 8,
                    }}
                >
                    <TextField
                        inputRef={inputRef}
                        label='Set a player name'
                        size='small'
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                setPlayerName(inputRef.current.value);
                            }
                        }}
                    />
                </Grid>
                <Grid
                    size = {{
                        xs: 4,
                    }}
                >
                    <Button
                        onClick={(event) => {
                            setPlayerName(inputRef.current.value);
                        }}
                        variant="contained"
                    >
                        {'Set'}
                    </Button>
                </Grid>
            </Grid>
            {playerDataError === 'NO_USER_DATA' && (
                <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
                    No RuneLite data found for this player. Make sure they have enabled the RuneLite Wiki sync plugin.
                </Alert>
            )}
            {playerDataError === 'FETCH_ERROR' && (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                    Error fetching player data. Please try again later.
                </Alert>
            )}
            <Typography
                variant='h5'
                textAlign={'center'}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}
            >
                {playerName}
                {playerName && (
                    <IconButton
                        size="small"
                        onClick={() => setPlayerName('')}
                        sx={{ ml: 1 }}
                    >
                        <ClearIcon />
                    </IconButton>
                )}
            </Typography>
            {playerName && playerStats.Hitpoints && <Grid
                container
                spacing={0.5}
                className="skill-grid"
            >
                {skillOrder.map((skill, index) => {
                    let icon = `https://oldschool.runescape.wiki/images/${skill}_icon.png?a4903`;

                    if(skill === 'Combat level'){
                        icon = 'https://oldschool.runescape.wiki/images/Combat_icon.png?93d63';
                    }

                    return (
                        <Grid
                            size = {{
                                xs: 4,
                            }}
                            key = {index}
                        >
                            <Stack
                                alignItems={'center'}
                                className='skill-wrapper'
                                direction={'row'}
                                justifyContent={'space-between'}
                                key={index}
                                spacing={1}
                            >
                                <Tooltip title={`${skill} - Click to view OSRS Wiki guide`}>
                                    <a
                                        href={`https://oldschool.runescape.wiki/w/${skill}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        <img
                                            alt={`${skill} icon`}
                                            className='skill-icon'
                                            src={icon}
                                        />
                                    </a>
                                </Tooltip>
                                <div
                                    className='skill-level-text-wrapper'
                                >
                                    {JSON.stringify(playerStats[skill])}
                                </div>
                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>}
        </Box>
    );
}