import {
    memo,
} from 'react';
import {
    Handle,
} from '@xyflow/react';

import ItemRow from './ItemRow';

import numberFormat from '../modules/number-format.mjs';

import '../item-node.css';

const ItemNode = ({data, type, sourcePosition, targetPosition}) => {
    return (<div
        className='item-node'
    >
        {(type === 'item' || type === 'itemOutput') && <Handle
            type="target"
            position={targetPosition}
        />}
        {(type === 'item' || type === 'itemInput') && <Handle
            type="source"
            position={sourcePosition}
        />}
        {(type === 'item' || type === 'itemOutput') && data.profit && <div
            className='item-cost'
        >
            Profit: {numberFormat(data.profit)}gp
        </div>
        }
        <ItemRow
            name={data.label}
            icon={data.icon}
            id={data.id}
            prefix={data.count > 1 ? `${data.count}x ` : ''}
        />

        <div
            className='item-price'
        >
            {data.count > 1 ? `Value: ${data.count} x ${numberFormat(data.price)}gp = ${numberFormat(data.price * data.count)}gp` : `Value: ${numberFormat(data.price)}gp`}
        </div>
    </div>);
};

export default memo(ItemNode);